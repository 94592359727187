<template>
  <div>
    <ErrorPage v-if="showError" />
    <div class="w-full h-full pt-5" v-else>
      <loader size="xxs" :loader-image="false" v-if="isLoading" />
      <template v-else>
        <template>
          <div class="flex mb-10 px-5">
            <h1 class="text-xl text-left font-extrabold mr-5">
              Employee Demographics
            </h1>
            <breadcrumb :items="breadcrumbs" />
          </div>
        </template>
        <template>
          <div class="flex flex-col gap-5">
            <div class="mx-5 rounded-md flex justify-between gap-5">
              <card
                class="flex flex-col justify-start items-start p-5 gap-5 w-3/5"
              >
                <div class="w-full flex flex-col gap-3 relative">
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="font-bold text-darkPurple text-lg">
                      Employee Distribution
                    </p>
                    <p class="font-normal text-jet text-sm">
                      Employee distribution by location.
                    </p>
                  </div>
                  <template v-if="locationDistribution">
                    <div class="flex justify-between gap-5 relative">
                      <card
                        class="w-3/5 h-11 flex items-center text-base text-darkPurple"
                      >
                        <c-select
                          class="w-full select-css"
                          variant="rm-border"
                          placeholder="All or Location"
                          v-model="locationDistributionId"
                          :options="['All Locations', ...locationOptions]"
                          @input="
                            getLocationDistributions({ locationId: $event })
                          "
                        />
                      </card>
                      <card
                        class="flex justify-evenly items-center gap-1 px-5 w-2/5 relative"
                      >
                        <div class="w-1/7 pr-2 border-r border-darkPurple">
                          <Icon icon-name="calendar" size="xs" />
                        </div>
                        <div class="w-5/7 flex">
                          <datepicker
                            placeholder="Year to Date"
                            input-class="w-32 pl-2"
                          />
                        </div>
                        <div class="w-1/7">
                          <Icon icon-name="caret-down" size="xs" />
                        </div>
                      </card>
                    </div>
                    <div style="height: 300px" v-if="isLocationLoading">
                      <loader size="xxs" :loader-image="false" />
                    </div>
                    <div
                      v-else
                      class="relative"
                      style="width: 100%; height: 100%"
                    >
                      <GoogleMap
                        styles="width:100%;height:400px"
                        :locations="locationDistribution"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="flex flex-col justify-center items-center text-center w-full h-full"
                    >
                      <Icon
                        icon-name="dashboard-error"
                        style="width: 350px; height: 350px"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">
                        There is no data to show currently.
                      </p>
                    </div>
                  </template>
                </div>
              </card>
              <card class="w-2/5 flex flex-col p-5 gap-4">
                <div class="text-darkPurple flex flex-col gap-5 w-full">
                  <div
                    class="text-darkPurple flex justify-between items-center w-full"
                  >
                    <p class="font-bold text-lg">Total No. of Employees</p>
                    <p class="font-extrabold text-4xl">{{ totalEmployees }}</p>
                  </div>
                  <template v-if="locationOptions">
                    <p class="font-bold text-lg text-darkPurple">
                      Top 5 Locations
                    </p>
                  </template>
                </div>
                <template v-if="locationOptions">
                  <div
                    class="bg-gray-50 rounded-md p-2 flex flex-col gap-8 relative overflow-y-auto h-96"
                  >
                    <div v-for="location in locationOptions" :key="location.id">
                      <div class="flex justify-between items-center gap-2">
                        <div class="flex justify-start items-start gap-2">
                          <div class="w-8 h-8 rounded-md" v-if="location.photo">
                            <img :src="location.photo" class="w-10 h-10" />
                          </div>
                          <div
                            v-else
                            class="border border-blueCrayola rounded-md w-10 h-10 flex justify-center"
                          >
                            <div
                              class="px-5 flex justify-center items-center font-bold"
                            >
                              {{ $getInitials(location.name) }}
                            </div>
                          </div>
                          <div
                            class="flex flex-col justify-start items-start gap-0"
                          >
                            <span
                              class="font-semibold text-darkPurple capitalize"
                              >{{ location.name }}</span
                            >
                            <span class="text-xs text-romanSilver uppercase">{{
                              location.address
                            }}</span>
                          </div>
                        </div>
                        <p class="text-romanSilver">
                          {{ location.employeesCount }} Employees
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="flex flex-col justify-center items-center text-center w-full h-full"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                </template>
              </card>
            </div>
            <div class="mx-5 rounded-md flex justify-between gap-5">
              <card
                class="w-3/5 p-5 flex flex-col justify-between items-start gap-5"
              >
                <div class="w-full flex flex-col gap-3">
                  <div class="flex flex-col justify-between gap-10">
                    <div class="w-full flex flex-col gap-3 relative">
                      <div class="flex flex-row justify-between items-center">
                        <div
                          class="flex flex-col justify-start items-start gap-0"
                        >
                          <p class="font-bold text-darkPurple text-lg">
                            Functions
                          </p>
                          <p class="font-normal text-jet text-sm">
                            Viewing distribution by functions.
                          </p>
                        </div>
                        <!-- <div class="px-3 flex items-center text-darkPurple h-8">
                        <c-select
                          v-if="functionChartData.labels"
                          variant="rm-border flex items-center w-20"
                          class="w-20"
                          placeholder="Top 3"
                          :options="['Select']"
                        />
                      </div> -->
                      </div>
                      <template v-if="functionChartData.labels">
                        <div class="flex justify-between gap-5 relative">
                          <div
                            class="flex justify-center items-center bg-ghostWhite px-2 rounded h-10"
                          >
                            <SearchInput
                              placeholder="Search"
                              class="-mt-2"
                              @input="
                                ($event) => {
                                  onGetQueryFunction(
                                    { search: $event },
                                    null,
                                    getFunctionDistributions
                                  );
                                }
                              "
                            />
                            <Icon
                              class-name="text-darkPurple"
                              size="xs"
                              icon-name="sort_size"
                            />
                          </div>
                          <card
                            class="flex justify-evenly items-center gap-1 px-5 w-2/5 relative"
                          >
                            <div class="w-1/7 pr-2 border-r border-darkPurple">
                              <Icon icon-name="calendar" size="xs" />
                            </div>
                            <div class="w-5/7 flex">
                              <datepicker
                                placeholder="Year to Date"
                                input-class="w-32 pl-2"
                              />
                            </div>
                            <div class="w-1/7">
                              <Icon icon-name="caret-down" size="xs" />
                            </div>
                          </card>
                        </div>
                        <template>
                          <div style="height: 300px" v-if="isFunctionLoading">
                            <loader size="xxs" :loader-image="false" />
                          </div>
                          <template v-else>
                            <div
                              class="flex flex-col justify-center items-center py-5 px-2 gap-5"
                            >
                              <BarChart
                                class="flex flex-col justify-between"
                                :options="functionChartOptions"
                                :chart-data="functionChartData"
                                :data="functionChartData"
                                :styles="{
                                  height: '100%',
                                  width: '100%',
                                  position: 'relative',
                                }"
                              />
                              <div
                                class="flex flex-wrap justify-center items-center gap-2 px-3"
                              >
                                <div
                                  class="flex justify-center items-center gap-1"
                                  v-for="legend in functionChartData.legends"
                                  :key="legend.label"
                                >
                                  <div
                                    class="rounded-full w-3 h-3"
                                    :style="`background: ${legend.bgColor}`"
                                  />
                                  <p class="text-xs text-jet">
                                    {{ legend.label }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div
                          class="flex flex-col justify-center items-center text-center w-full h-full"
                        >
                          <Icon
                            icon-name="dashboard-error"
                            style="width: 350px; height: 350px"
                          />
                          <p class="text-xl font-bold">No Data Available</p>
                          <p class="text-xs text-romanSilver">
                            There is no data to show currently.
                          </p>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </card>
              <card
                class="w-2/5 p-5 flex flex-col justify-between items-start gap-5"
              >
                <div class="w-full h-full flex flex-col gap-3">
                  <div class="flex justify-between items-center">
                    <div class="flex flex-col justify-start items-start gap-0">
                      <p class="font-bold text-darkPurple text-lg">Gender</p>
                      <p class="font-normal text-jet text-sm">
                        Viewing distribution by gender
                      </p>
                    </div>
                  </div>
                  <template>
                    <template v-if="genderChartData.labels">
                      <div class="flex flex-col justify-start gap-2">
                        <div
                          class="flex flex-col justify-between gap-5 relative"
                        >
                          <div
                            class="flex justify-between items-center bg-ghostWhite px-2 rounded h-10"
                          >
                            <SearchInput
                              placeholder="Search"
                              class="-mt-2"
                              @input="
                                ($event) => {
                                  onGetQueryFunction(
                                    { search: $event },
                                    null,
                                    getGenderDistributions
                                  );
                                }
                              "
                            />
                            <Icon
                              class-name="text-darkPurple"
                              size="xs"
                              icon-name="sort_size"
                            />
                          </div>
                          <card
                            class="flex justify-between items-center gap-1 px-5 h-10 relative"
                          >
                            <div
                              class="w-1/7 flex flex-row justify-start items-center gap-2"
                            >
                              <div class="pr-2 border-r border-darkPurple">
                                <Icon icon-name="calendar" size="xs" />
                              </div>
                              <div class="w-5/7 flex">
                                <datepicker
                                  placeholder="Year to Date"
                                  input-class="w-32 pl-2"
                                />
                              </div>
                            </div>
                            <div class="w-1/7">
                              <Icon icon-name="caret-down" size="xs" />
                            </div>
                          </card>
                        </div>
                        <template>
                          <div style="height: 300px" v-if="isGenderLoading">
                            <loader size="xxs" :loader-image="false" />
                          </div>
                          <div
                            v-else
                            class="flex justify-center items-center mt-16"
                          >
                            <DoughnutChart
                              :options="genderChartOptions"
                              :data="genderChartData"
                              :value="totalGenderCount"
                              :styles="{
                                height: '300px',
                                width: '300px',
                                position: 'relative',
                              }"
                            />
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="flex flex-col justify-center items-center text-center w-full h-full"
                      >
                        <Icon
                          icon-name="dashboard-error"
                          style="width: 350px; height: 350px"
                        />
                        <p class="text-xl font-bold">No Data Available</p>
                        <p class="text-xs text-romanSilver">
                          There is no data to show currently.
                        </p>
                      </div>
                    </template>
                  </template>
                </div>
              </card>
            </div>
            <card
              class="mx-5 p-5 flex flex-col justify-between items-start gap-5"
            >
              <div class="w-full flex flex-col gap-3">
                <div class="flex flex-col justify-start items-start gap-0">
                  <template v-if="activeEmploymentType === 'employmentType'">
                    <p class="font-bold text-darkPurple text-lg">
                      Employment Type
                    </p>
                    <p class="font-normal text-jet text-sm">
                      Viewing distribution by employment type
                    </p>
                  </template>
                  <template v-if="activeEmploymentType === 'supervisory'">
                    <p class="font-bold text-darkPurple text-lg">
                      Supervisory Role
                    </p>
                    <p class="font-normal text-jet text-sm">
                      Viewing distribution by supervisory role
                    </p>
                  </template>
                </div>
                <template>
                  <div class="flex justify-between gap-5 relative">
                    <div
                      class="flex justify-between items-center bg-ghostWhite px-2 rounded h-10"
                    >
                      <SearchInput
                        placeholder="Search"
                        class="-mt-2"
                        @input="
                          ($event) => {
                            onGetQueryFunction(
                              { search: $event },
                              null,
                              getEmploymentTypeDistributions
                            );
                          }
                        "
                      />
                      <Icon
                        class-name="text-darkPurple"
                        size="xs"
                        icon-name="sort_size"
                      />
                    </div>
                    <card>
                      <c-select
                        variant="rm-border"
                        :options="[
                          { name: 'Employment Type', id: 'employmentType' },
                          { name: 'Supervisory Role', id: 'supervisory' },
                        ]"
                        v-model="activeEmploymentType"
                        @input="
                          ($event) => {
                            onGetQueryFunction(
                              { type: $event },
                              null,
                              getEmploymentTypeDistributions
                            );
                          }
                        "
                      />
                    </card>
                  </div>
                  <div style="height: 300px" v-if="isLoadingEmploymentType">
                    <loader size="xxs" :loader-image="false" />
                  </div>
                  <template v-else>
                    <template v-if="employmentTypeChartData.labels">
                      <div class="flex flex-col justify-start gap-2">
                        <div
                          class="flex flex-col justify-center items-center py-5 px-2 gap-5"
                        >
                          <HorizontalBarChart
                            :options="employmentTypeChartOptions"
                            :chart-data="employmentTypeChartData"
                            :data="employmentTypeChartData"
                            :styles="{
                              height: `${
                                employmentTypeChartData.legends.length * 70
                              }px`,
                              gap: '4px',
                              width: '100%',
                              position: 'relative',
                            }"
                          />
                          <div
                            class="w-3/5 flex flex-wrap justify-center items-center gap-2"
                          >
                            <div
                              class="flex justify-center items-center gap-1"
                              v-for="legend in employmentTypeChartData.legends"
                              :key="legend.label"
                            >
                              <div
                                class="rounded-full w-3 h-3"
                                :style="`background: ${legend.bgColor}`"
                              />
                              <p class="text-xs text-jet">{{ legend.label }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="flex flex-col justify-center items-center text-center w-full h-full"
                      >
                        <Icon
                          icon-name="dashboard-error"
                          style="width: 300px; height: 300px"
                        />
                        <p class="text-xl font-bold">No Data Available</p>
                        <p class="text-xs text-romanSilver">
                          There is no data to show currently.
                        </p>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </card>
            <card
              class="mx-5 p-5 flex flex-col justify-between items-start gap-5"
            >
              <div class="w-full flex flex-col gap-3">
                <div class="flex flex-col justify-start items-start gap-0">
                  <p class="font-bold text-darkPurple text-lg">Pay Type</p>
                  <p class="font-normal text-jet text-sm">
                    Viewing distribution by pay type
                  </p>
                </div>
                <template>
                  <div style="height: 200px" v-if="isLoadingPayType">
                    <loader size="xxs" :loader-image="false" />
                  </div>
                  <template v-else>
                    <template v-if="payTypeChartData.labels">
                      <div
                        class="flex justify-between items-center bg-ghostWhite px-2 rounded h-10 w-72"
                      >
                        <SearchInput placeholder="Search" class="-mt-2 w-60" />
                        <Icon
                          class-name="text-darkPurple"
                          size="xs"
                          icon-name="sort_size"
                        />
                      </div>
                      <div class="flex flex-col justify-start gap-2">
                        <div
                          class="flex flex-col justify-center items-center py-5 px-2 gap-5"
                        >
                          <HorizontalBarChart
                            :options="payTypeChartOptions"
                            :chart-data="payTypeChartData"
                            :data="payTypeChartData"
                            :styles="{
                              height: '150px',
                              width: '100%',
                              position: 'relative',
                            }"
                          />
                          <div
                            class="w-1/2 flex flex-wrap justify-center items-center gap-2"
                          >
                            <div
                              class="flex justify-center items-center gap-1"
                              v-for="legend in payTypeChartData.legends"
                              :key="legend.label"
                            >
                              <div
                                class="rounded-full w-3 h-3"
                                :style="`background: ${legend.bgColor}`"
                              />
                              <p class="text-xs text-jet">{{ legend.label }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        class="flex flex-col justify-center items-center text-center w-full h-full"
                      >
                        <Icon
                          icon-name="dashboard-error"
                          style="width: 200px; height: 200px"
                        />
                        <p class="text-xl font-bold">No Data Available</p>
                        <p class="text-xs text-romanSilver">
                          There is no data to show currently.
                        </p>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </card>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { startCase } from "lodash";
import Datepicker from "vuejs-datepicker";
import Gradient from "javascript-color-gradient";
import Loader from "@scelloo/cloudenly-ui/src/components/loader";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import DoughnutChart from "@/components/donutChart";
import HorizontalBarChart from "@/components/HorizontalBarChart";
import BarChart from "@/components/BarChart";
import Icon from "@/components/Icon";
import GoogleMap from "@/components/GoogleMap";
import ErrorPage from "@/modules/Admin/error403";

export default {
  components: {
    Loader,
    Datepicker,
    ErrorPage,
    Breadcrumb,
    BarChart,
    HorizontalBarChart,
    DoughnutChart,
    CSelect,
    SearchInput,
    Card,
    Icon,
    GoogleMap,
  },
  data() {
    return {
      isLoading: true,
      showError: false,
      isFunctionLoading: true,
      isLoadingPayType: true,
      isLoadingEmploymentType: true,
      isLocationLoading: true,
      isGenderLoading: true,
      setQueryTimeout: null,
      queryParams: {},
      locationOptions: [],
      locationDistribution: [],
      locationDistributionId: "",
      functionDistributionId: "",
      genderDistributionId: "",
      employmentTypeDistributionId: "",
      totalGenderCount: "",
      totalEmployees: "",
      genderChartData: {},
      functionChartData: {},
      payTypeChartData: {},
      employmentTypeChartData: {},
      activeEmploymentType: "employmentType",
      genderChartOptions: {
        plugins: {
          labels: {
            render: "percentage",
            fontColor: "red",
            position: "outside",
          },
        },
        legend: {
          display: true,
          position: "bottom",
          labels: { usePointStyle: true, fontSize: 10, fontColor: "#321C3B" },
        },
        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: 60,
      },
      functionChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          xAxes: [
            { ticks: { beginAtZero: true }, gridLines: { display: false } },
          ],
          yAxes: [
            {
              scaleLabel: { display: true, labelString: "Number of Employees" },
              gridLines: {
                display: true,
                color: "#878E99",
                borderDash: [4, 4],
              },
            },
          ],
        },
      },
      payTypeChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "bottom",
          labels: { usePointStyle: true, fontSize: 9, fontColor: "#333333" },
        },
        scales: {
          xAxes: [
            {
              ticks: { beginAtZero: true },
              scaleLabel: {
                display: true,
                labelString: "Total No of Employees",
              },
              gridLines: {
                display: true,
                color: "#878E99",
                borderDash: [4, 4],
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: { display: true, labelString: "Pay Type" },
              gridLines: { display: false },
            },
          ],
        },
      },
      employmentTypeChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "bottom",
          labels: { usePointStyle: true, fontSize: 9, fontColor: "#333333" },
        },
        scales: {
          xAxes: [
            {
              ticks: { beginAtZero: true },
              scaleLabel: {
                display: true,
                labelString: "Total No of Employees",
              },
              gridLines: {
                display: true,
                color: "#878E99",
                borderDash: [4, 4],
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: { display: true, labelString: "Employment Type" },
              gridLines: { display: false },
            },
          ],
        },
      },
      breadcrumbs: [
        {
          disabled: false,
          text: "Analytics",
          href: "Analytics",
          id: "AnalyticsEmployees",
        },
        {
          disabled: false,
          text: "Employee Demographics",
          href: "EmployeeDemographics",
          id: "EmployeeDemographics",
        },
      ],
    };
  },
  methods: {
    onGetQueryFunction(queryParams, payload, callback) {
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = setTimeout(() => {
        if (queryParams === undefined) {
          this.queryParams = {};
          return callback({ ...this.queryParams, ...payload });
        }
        this.queryParams = { ...this.queryParams, ...queryParams };
        return callback({ ...this.queryParams, ...payload });
      }, 1000);
    },
    getLocationDistributions({ locationId }) {
      if (locationId === "All Locations") locationId = "";
      this.isLocationLoading = true;
      this.$_getOrgLocationDistribution({ locationId }).then(({ data }) => {
        this.locationDistribution = data.locations;
        if (!locationId) {
          this.locationOptions = data.locations.map((item) => ({
            value: item.id,
            ...item,
          }));
        }
        this.totalEmployees = data.totalEmployee;
        this.isLocationLoading = false;
      });
    },
    getFunctionDistributions(queryParams) {
      this.isFunctionLoading = true;
      this.$_getOrgFunctionDistribution({ queryParams }).then(({ data }) => {
        const functionLabels = data.data?.map((item) => item.name);
        const employeeCount = data.data?.map((item) => item.employeeCount);
        const COLORS = new Gradient()
          .setColorGradient(
            "#13B56A",
            "#2176FF",
            "#EA3C53",
            "#878E99",
            "#F15A29",
            "#00D5E0"
          )
          .setMidpoint(employeeCount.reduce((a, b) => a + b, 0))
          .getColors();
        const legendsArray = functionLabels?.map((legend, i) => ({
          label: startCase(legend),
          bgColor: COLORS[i],
        }));

        this.functionChartData = {
          datasets: [{ data: employeeCount, backgroundColor: COLORS }],
          labels: functionLabels,
          legends: legendsArray,
        };
        this.isFunctionLoading = false;
      });
    },
    getGenderDistributions(queryParams) {
      this.isGenderLoading = true;
      this.$_getOrgGenderDistribution(queryParams).then(({ data }) => {
        const { maleCount, femaleCount, unspecifiedCount } = data.data;
        const totalGenderCount = [maleCount, femaleCount, unspecifiedCount];
        this.totalGenderCount = totalGenderCount?.reduce((a, b) => a + b, 0);

        this.genderChartData = {
          labels: ["Male", "Female", "Unspecified"],
          datasets: [
            {
              data: [maleCount, femaleCount, unspecifiedCount],
              backgroundColor: ["#13B56A", "#F15A29", "#3454d1"],
            },
          ],
        };
        this.isGenderLoading = false;
      });
    },
    async getPayTypeDistribution() {
      this.isLoadingPayType = true;
      await this.$_getOrgPayTypeDistribution().then(({ data }) => {
        if (data.data.salaried > 0 || data.data.hourly > 0) {
          const COLORS = ["#13B56A", "#2176FF"];
          const payTypeData = [data.data.salaried, data.data.hourly];
          const payTypeLabels = [
            ["Salaried", "Employees"],
            ["Hourly", "Employees"],
          ];
          const payTypeLegends = payTypeLabels?.map((legend, i) => ({
            label: startCase(legend),
            bgColor: COLORS[i],
          }));

          this.payTypeChartData = {
            labels: payTypeLabels,
            legends: payTypeLegends,
            datasets: [{ data: payTypeData, backgroundColor: COLORS }],
          };
        }
        this.isLoadingPayType = false;
      });
    },
    getEmploymentTypeDistributions(queryParams) {
      this.isLoadingEmploymentType = true;
      this.$_getOrgEmploymentDistribution(queryParams).then(({ data }) => {
        const employmentsData = Object.values(data.data);

        if (employmentsData.some((v) => v > 0)) {
          const COLORS = new Gradient()
            .setColorGradient(
              "#13B56A",
              "#2176FF",
              "#EA3C53",
              "#878E99",
              "#F15A29",
              "#00D5E0"
            )
            .setMidpoint(Object.keys(data.data)?.length)
            .getColors();
          const employmenTypeLabels = Object.keys(data.data)?.map((label) => [
            ...startCase(label).split(" "),
          ]);
          const legendsArray = employmenTypeLabels?.map((legend, i) => ({
            label: startCase(legend),
            bgColor: COLORS[i],
          }));

          if (this.activeEmploymentType === "employmentType") {
            this.employmentTypeChartOptions.scales.yAxes[0].scaleLabel.labelString =
              "Employment Type";
          } else
            this.employmentTypeChartOptions.scales.yAxes[0].scaleLabel.labelString =
              "Supervisory Role";

          this.employmentTypeChartData = {
            labels: employmenTypeLabels,
            legends: legendsArray,
            datasets: [{ data: employmentsData, backgroundColor: COLORS }],
          };
        }
        this.isLoadingEmploymentType = false;
      });
    },
    async bootstrapModule() {
      this.getLocationDistributions({});
      this.getFunctionDistributions({});
      this.getGenderDistributions({});
      this.getEmploymentTypeDistributions({ type: "employmentType" });
      this.getPayTypeDistribution();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      await this.$handlePrivilege("analyticsEmployee", "viewEmployeeAnalytics");
      this.showError = false;
      await this.bootstrapModule();
      this.isLoading = false;
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .rm-border {
  border: none !important;
}
::v-deep .select-class {
  display: flex;
  justify-content: space-between;
}
::v-deep .vdp-datepicker__calendar {
  margin-left: -100px !important;
  color: #321c3b !important;
}
::v-deep .vdp-datepicker {
  color: #321c3b !important;
  input[type="text"] {
    color: #321c3b !important;
    &:hover,
    &:focus {
      outline: none !important;
      color: #321c3b !important;
    }
  }
}
::v-deep .select-css span {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner-donut-function {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 232px;
  top: -35px;
}
.inner-donut-gender {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 212px;
  top: -15px;
}
.color-white {
  color: white !important;
}
</style>
